import { useNavigate } from 'react-router-dom';

const Navbar = () => { 
  const navigate = useNavigate();

  const handleSectionChange = (section) => {
    switch (section) {
      case 'Projects':
        navigate('/projects');
        break;
      case 'About':
        navigate('/about');
        break;
      case 'Home':
        navigate('/');
        break;
      default:
        navigate('/');
    }
  };

  return (
    <nav className='navBar'>
      <div className='imageDiv'>
          <img 
              src={process.env.PUBLIC_URL + `/img/logo.png`} 
              className="logoNavBar" 
              alt="Logo"
              onClick={() => handleSectionChange('Home')}
          />
      </div>

      <div className='textDiv'> 
          <span onClick={() => handleSectionChange('Projects')}>Projects</span>
          <span onClick={() => handleSectionChange('About')}>About</span> 
      </div>
    </nav>
  );
};

export default Navbar;

