import React, { useState, useEffect } from 'react'; 
import Navbar from './Navbar';

const projects = [
  {
    title: 'Meme generator',
    imageSrc:process.env.PUBLIC_URL + "/img/meme.png",
    description: 'Meme generator using an API for random images.',
    link: 'https://clemi.zapto.org/memegenerator',
    githubLink:"https://github.com/clemzouuu/react-meme-generator",
  },
  {
    title: "Jojo's Bizarre Adventure",
    imageSrc:process.env.PUBLIC_URL + "/img/jjba.png",
    description: 'Static page display JJBA characters and their voices.',
    link: 'https://clemi.zapto.org/jjba',
    githubLink:"https://github.com/clemzouuu/Jojo-s-Bizarre-Adventure"
  }, 
  {
    title: 'Paint',
    imageSrc:process.env.PUBLIC_URL + "/img/paint.png",
    description: 'Paint replica.',
    link: 'https://clemi.zapto.org/paint',
    githubLink:"https://github.com/clemzouuu/Paint-Provisoire"
  }, 
  {
    title: 'Météo',
    imageSrc:process.env.PUBLIC_URL + "/img/meteo.png",
    description: 'App displaying weather forecast via an API.',
    link: 'https://clemi.zapto.org/meteo',
    githubLink:"https://github.com/clemzouuu/App-meteo"
  }, 
  {
    title: 'Piano',
    imageSrc:process.env.PUBLIC_URL + "/img/piano.png",
    description: 'App to play the piano.',
    link: 'https://clemi.zapto.org/piano',
    githubLink:"https://github.com/clemzouuu/Piano"
  }, 
  {
    title: 'Client Side Dungeon Crawler',
    imageSrc:process.env.PUBLIC_URL + "img/dungeon.png",
    description: 'Browser Dungeon Crawler.',
    link: 'https://clemi.zapto.org/dungeon',
    githubLink:"https://github.com/clemzouuu/Dungeon-Crawler"
  }, 
  {
    title: 'Pokedex', 
    imageSrc:process.env.PUBLIC_URL + "/img/pokemon.png",
    githubLink:"https://github.com/clemzouuu/React-pokedex"
  }, 
  {
    title: 'Doomsday',
    imageSrc:process.env.PUBLIC_URL + "/img/doomsday.png",
    description: 'Game made in C, playable in the terminal.',
    githubLink:"https://github.com/clemzouuu/Doomsday"
  }, 
  {
    title: 'Esengo',
    imageSrc:process.env.PUBLIC_URL + "/img/esengo.png",
    description: 'Python interpretor.',
    githubLink:"https://github.com/clemzouuu/Esengo"
  }, 
  {
    title: 'Dream',
    imageSrc:process.env.PUBLIC_URL + "/img/rpg.png",
    description: 'Game made in Python.',
    githubLink:"https://github.com/clemzouuu/RPG-Project"
  }, 
  {
    title: 'Gaumont',
    imageSrc:process.env.PUBLIC_URL + "/img/gaumont.png",
    description: 'Movie theater management app using express js.',
    githubLink:"https://github.com/clemzouuu/gestion-cinema-express-js"
  }
];

const Portfolio = () => {
  return (
    <>
      <Navbar/>
      <div className="portfolio">
        <div className="projects-grid">
          {projects.map((project, index) => (
            <div className="project-card" key={index}>
              <img src={project.imageSrc} alt={project.title} className="project-image" />
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              { project.link && 
                <span className='projectText'>
                    <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">View Project</a>
                    <br/>
                </span>
              }
              <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="github-link">View Code</a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Portfolio;
