import React, { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('');  
    const [opacity, setOpacity] = useState(1);  
    const [isHomeVisible, setIsHomeVisible] = useState(true);  
  
    useEffect(() => {
      const interval = setInterval(() => {
        setOpacity((prevOpacity) => (prevOpacity === 1 ? 0 : 1));  
      }, 3000);
  
      return () => clearInterval(interval); 
    }, []);
  
    const handleSectionChange = (section) => {
        setActiveSection(section);
        setIsHomeVisible(false);  

        switch (section) {
          case 'Projects':
            navigate('/projects');
            break;
          case 'About':
            navigate('/about');
            break; 
          case 'Home':
            navigate('/');
            break;
          default:
            navigate('/');
        }
      };
  
    return (
        <>
            {isHomeVisible && (
                <div className='homePageComponents'>
                    <img 
                        src={process.env.PUBLIC_URL + `/img/logo.png`} 
                        className="logo"
                        style={{ opacity }}
                        alt="Logo"
                        onClick={() => handleSectionChange('Home')}
                    />
                
                    <div className='homePageText'>
                        <span onClick={() => handleSectionChange('Projects')}>Projects</span>
                        <span onClick={() => handleSectionChange('About')}>About</span>
                     </div>
                </div>
            )}
        </>
    );
  }
  
  export default Home;
  