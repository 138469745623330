import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Portfolio from './Components/Portfolio';
import About from './Components/About'; 
import Home from './Components/Home';
import NotFound from './Components/NotFound';

function App() {
  const [activeSection, setActiveSection] = useState('');  
  const [opacity, setOpacity] = useState(1);  
  const [isHomeVisible, setIsHomeVisible] = useState(true);  

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity((prevOpacity) => (prevOpacity === 1 ? 0 : 1));  
    }, 3000);

    return () => clearInterval(interval); 
  }, []);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setIsHomeVisible(false);  
  };

  const renderActiveSection = () => {
    switch (activeSection) {
      case 'Projects':
        return <Portfolio />;
      case 'About':
        return <About />;
      default:
        return null;
    }
  };

  return (
    <>
      <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Portfolio />} /> 
              <Route path="*" element={<NotFound />} />
            </Routes>
      </Router>
    </>
  );
}

export default App;
