// NotFound.js
import React from 'react';

function NotFound() {
  return (
    <div>
        <h1>Error 404 - Page not found</h1>
        <img 
            src={process.env.PUBLIC_URL + `/img/404.gif`}  
            alt="spongeBobCrying" 
        />
    </div>
  );
}

export default NotFound;
